import "./App.css";
import { Routes, Route } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";
import HomePage from "./pages/HomePage";
import EducationPage from "./pages/EducationPage";
import ExperiencePage from "./pages/ExperiencePage";
import ProjectPage from "./pages/ProjectPage";
import ContactPage from "./pages/ContactPage";

function App() {
  const appClassDark: string =
    "relative bg-gray-900 min-h-screen text-gray-200";
  const appClassLight: string = "relative bg-gray-200 min-h-screen w-screen";
  return (
    <div className="relative bg-gray-200 min-h-screen min-w-fit">
      <Menu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/experience" element={<ExperiencePage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
