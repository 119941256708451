import icon from "../media/profile-icon.png";

const HomePage = () => {
  return (
    <>
      <h1 className="text-2xl text-center bg-inherit z-10">
        Welcome to my Portfolio Page
      </h1>
      <div className="absolute text-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 grid grid-cols-3">
        <img src={icon} className="w-64 grid-cols-1 hover:w-72" />
        <p className="col-span-2 relative">
          I am a third year Computer Science student with a strong interest in
          programming. A caring and committed student with strong interpersonal
          skills hoping to find an internship alongside my studies.
        </p>
      </div>
    </>
  );
};

export default HomePage;
