import Autoplay from "embla-carousel-autoplay";
import polygarden from "../media/polygarden.png";
import cyberclimb from "../media/cyberclimb.png";
import useEmblaCarousel from "embla-carousel-react";
import "../embla.css";

const ProjectPage = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  return (
    <>
      <h1 className="text-2xl text-center bg-inherit z-10 md:mb-5 mb-20">
        My Projects
      </h1>
      <div className="relative embla left-1/2 -translate-x-1/2" ref={emblaRef}>
        {/* Slide description still hidden sometimes */}
        <div className="embla__container">
          <div className="embla__slide w-fit h-full">
            <img className="embla__img w-full" src={polygarden} />
            <p className="w-full text-center">
              Polygarden NFT Marketplace (Group Project)
            </p>
          </div>
          <div className="embla__slide w-fit h-fit">
            <img className="embla__img w-full" src={cyberclimb} />
            <p className="w-full text-center">
              Cyberclimb Web3 Game - Built on ImmutableX (Group Project)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
