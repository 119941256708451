import { Link } from "react-router-dom";
import "./customPadding.css";

const styleStringLight: string =
  "text-black hover:text-white hover:bg-gray-600 rounded p-7px sm:p-2 m-1 text-right font-quicksand-light";
const styleStringDark: string =
  "text-white hover:text-black hover:bg-gray-600 rounded p-2 m-1 text-right font-quicksand-light";

function Menu() {
  return (
    <nav className="border-b-2 border-white p-2.5 border-transparent bg-inherit sticky top-0 z-50 sm:text-base text-xs">
      <ul>
        <Link to="/" className={styleStringLight}>
          Home
        </Link>
        <Link to="/education" className={styleStringLight}>
          Education
        </Link>
        <Link to="/experience" className={styleStringLight}>
          Experience
        </Link>
        <Link to="/projects" className={styleStringLight}>
          Projects
        </Link>
        <Link to="/contact" className={styleStringLight}>
          Contact
        </Link>
      </ul>
    </nav>
  );
}

export default Menu;
