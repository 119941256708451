import TimelinePage from "./TimelinePage";
import MotionArticle from "../MotionArticle";

const ExperiencePage = () => {
  return (
    <TimelinePage header="Employment and Work Experience">
      <MotionArticle
        animationDelay={0.1}
        date="July - September 2023"
        header="Pirum Systems Intership"
      >
        "Three month internship. My time at Pirum has given me great insight
        into what it’s like to work in a Fintech, and in the Securities Lending
        industry. I’ve been able to experience in great detail the day to day
        operations in a Client Services team, and the expertise required to meet
        the various client needs on what are often short deadlines, while still
        maintaining an enjoyable workplace atmosphere. I’ve also been extended
        the privilege of in depth demonstrations by employees in numerous other
        roles within the company, including many of the ExCo members."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="June 2023"
        header="King’s Undergraduate Research Fellowship"
      >
        "One month fellowship placement. I applied Split Learning principles to
        a Pytorch neural network, collecting experimental data on the potential
        benefits of separating the training process across a client and server.
        This utilised a 5G network and Intel Smart Edge, a piece of software
        based on Kubernetes and Docker, allowing seamless access to the
        server/edge side of the code without the need to manually restart or
        redeploy the program. The collected data was then included in a
        published research paper."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="December 2022"
        header="Technical Consultant at King’s Junior Consulting"
      >
        "Part Time Consulting. The time spent in this placement has given me
        great insight into how a professional software engineering project is
        managed. It has also motivated me to learn to use new tools,
        specifically Firebase and Flutterflow for app development."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="May 2022 - May 2023"
        header="Food and Beverage Assistant at David Lloyd"
      >
        "Part Time job. Working face to face with members of the club has given
        me the opportunity to converse with a wide range of individuals from
        contrasting backgrounds and cultures, gaining experience through the
        advice they give and hearing their life experiences. This has given me
        strong interpersonal skills to allow me to work comfortably with
        different types of people in a peaceful and effective way. The busy
        nature of the job has taught me how to work well under pressure, as it
        is not uncommon to be the only employee on shift in my role,
        occasionally even at the busiest times of the day. My consistency in
        positive working attitude and willingness to go the extra mile earned me
        a reputation for being reliable and trustworthy. The experience I
        acquired in a short space of time has allowed me the privilege of
        training and teaching new employees in accordance with safety and
        hygiene standards, while remaining efficient and maintaining customer
        satisfaction."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="August 2021"
        header="Buffalo Fundraising Consultants"
      >
        "Two week paid placement. Telephone fundraising. I contacted parents and
        former students to raise money for school bursaries and a hardship fund
        to assist students impacted by the pandemic. I was able to develop
        negotiation skills and learn how to deal with disgruntled members of the
        public."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="July 2020"
        header="Smart-Le Online Revision"
      >
        "One month volunteering position. I wrote revision notes and questions
        for GCSE students to use during lockdown. This allowed me to demonstrate
        and improve my ability to convey information to others in the most
        digestible way possible."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="June 2019"
        header="DST Systems Branches (Brentwood, Basildon and Chelmsford)"
      >
        "One week work experience placement. I was able to observe and question
        different members of staff to gain understanding of many different
        departments in the company, travelling between different branches within
        Essex to see how they linked and worked together."
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="July 2018 - December 2018"
        header="Cancer Research Charity Shop Northwood"
      >
        "Six months volunteering position. This experience allowed me to gain
        understanding of the importance of volunteer work in the funding of the
        charity by my active involvement in restocking, processing and movement
        of donated clothes and dealing with customers."
      </MotionArticle>
    </TimelinePage>
  );
};

export default ExperiencePage;
