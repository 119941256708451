import github from "./media/github.svg";
import linkedin from "./media/linkedin.svg";

const Footer = () => {
  const footerLinkClass: string =
    "p-4 m-1 rounded float-right hover:text-white hover:bg-gray-600";
  const footerImageClass: string = "float-right h-6 ml-1";
  const footerNameClass: string = "float-right";
  return (
    <ul className="absolute border-t-2 h-15 border-white bg-inherit w-full bottom-0 z-50">
      <li>
        <a className={footerLinkClass} href="https://github.com/lucab1111">
          <img className={footerImageClass} src={github} />
          <p className={footerNameClass}>Github </p>
        </a>
      </li>
      <li>
        <a
          className={footerLinkClass}
          href="https://www.linkedin.com/in/lucaboccardo/"
        >
          <img className={footerImageClass} src={linkedin} />
          <p className={footerNameClass}>LinkedIn </p>
        </a>
      </li>
    </ul>
  );
};

export default Footer;
