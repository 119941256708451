import { motion } from "framer-motion";

const MotionArticle = ({
  animationDelay,
  date,
  header,
  children,
}: {
  animationDelay: number;
  date: string;
  header: string;
  children: any;
}) => {
  return (
    <div className="container grid md:grid-cols-3 gap-4 md:gap-8 mb-16 w-11/12">
      <motion.div
        initial={{ scale: "0%", opacity: "0%" }}
        whileInView={{ scale: "100%", opacity: "100%" }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
          delay: animationDelay,
        }}
        className="pl-12 md:pl-0 md:text-right md:leading-loose uppercase text-gray-500"
      >
        {date}
      </motion.div>
      <div className="pl-12 col-span-2 relative">
        <motion.div
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: animationDelay,
          }}
          className="rounded-full bg-black border-2 border-gray-300 w-4 h-4 absolute mt-2 -ml-12"
        ></motion.div>
        <motion.h2
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: animationDelay,
          }}
          className="font-medium"
        >
          {header}
        </motion.h2>
        <motion.p
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: animationDelay,
          }}
        >
          {children}
        </motion.p>
      </div>
    </div>
  );
};

export default MotionArticle;
