const TimelinePage = ({
  header,
  children,
}: {
  header: string;
  children: any;
}) => {
  return (
    <>
      <h1 className="relative text-2xl text-center bg-inherit z-10">
        {header}
      </h1>
      <div>
        <div className="absolute inset-0 -z-0">
          <div className="container grid md:grid-cols-3 gap-8 items-center h-full">
            <div className="md:col-start-2 h-full ml-2">
              <div className="w-0.5 bg-black h-full fixed" />
            </div>
          </div>
        </div>
        {children}
        <div className="h-16" />
      </div>
    </>
  );
};

export default TimelinePage;
