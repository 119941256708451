import telephone from "../media/telephone.svg";
import email from "../media/email.svg";
import { motion } from "framer-motion";

const ContactPage = () => {
  const inputStyle: string = "mb-4 align-top w-2/3 max-w-sm";
  const labelStyle: string = "flex flex-col justify-start";
  // max-w-sm w-7/12
  // relative left-1/2 -translate-x-1/2 mt-5 align-top text-2xl w-11/12 md:w-7/12 md:ml-10 flex lg:flex-row flex-col
  return (
    <>
      <h1 className="text-2xl text-center bg-inherit z-10">Contact</h1>
      <div className="relative left-1/2 -translate-x-1/2 mt-5 align-top lg:w-2/3 w-4/5 flex flex-col items-center">
        <motion.p
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="m-4 text-center text-xl md:text-2xl lg:text-3xl"
        >
          Please get in contact with the below form to send a direct email if
          you have any inquiries. I will do my best to get back to you in a
          timely manner.
        </motion.p>
        <motion.form
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="m-4 w-full"
        >
          <label className={labelStyle}>
            Name:
            <input name="name" className={inputStyle} type="text"></input>
          </label>
          <label className={labelStyle}>
            Email Address:
            <input
              name="emailAddress"
              className={inputStyle}
              type="email"
            ></input>
          </label>
          <label className={labelStyle}>
            Message:
            <textarea name="message" className="mb-4 align-top h-64"></textarea>
            {/* <textarea className={inputStyle.concat(" max-w-2xl h-64")}></textarea> */}
          </label>
        </motion.form>
        <motion.div
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
        >
          Or contact me directly with:
        </motion.div>

        <motion.div
          initial={{ scale: "0%", opacity: "0%" }}
          whileInView={{ scale: "100%", opacity: "100%" }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="flex flex-row justify-center mt-2 mb-32"
        >
          <img className="h-6 mr-2" src={telephone} />
          <p className="mr-10 sm:text-base text-xs">Mobile: 00000000000</p>
          <img className="h-6 mr-2" src={email} />
          <p className="mr-10 sm:text-base text-xs">Email: example@email.com</p>
        </motion.div>
      </div>
    </>
  );
};

export default ContactPage;
