import TimelinePage from "./TimelinePage";
import MotionArticle from "../MotionArticle";

const EducationPage = () => {
  return (
    <TimelinePage header="Education">
      <MotionArticle
        animationDelay={0.1}
        date="2021 - Present Day"
        header="Integrated Masters Computer Science Student at King’s College London University"
      >
        Experienced in Python, Java, C++, Scala, Typescript and Solidity
        <br />
        Awarded KCL Sambrooke Exhibition in Natural Science (Informatics) for
        highest overall Informatics Marks in 22-23 Academic Year Awarded King’s
        <br />
        Undergraduate Research Fellowship (KURF) Award 2023
      </MotionArticle>
      <MotionArticle
        animationDelay={0.1}
        date="2014 – 2021"
        header="Bursary Student at Haberdashers’ Aske’s Boys’ School, Elstree"
      >
        <h3 className="font-medium">Summer 2019: GCSEs:</h3>
        <div className="grid grid-cols-3">
          <div>English Language (Grade 9)</div> <div>Mathematics (Grade 9)</div>
          <div>Chemistry (Grade 9)</div>
          <div>English Literature (Grade A*)</div> <div>Biology (Grade 9)</div>
          <div>Spanish (Grade 9)</div>
          <div>Geography (Grade 9)</div> <div>Physics (Grade 9)</div>
          <div>Latin (Grade 9)</div> <div>Computer Science (Grade 9)</div>
          <div>FSMQ Additional Maths (Grade A)</div>
        </div>
        <br />
        <h3 className="font-medium">Summer 2020: AS Levels:</h3>
        <div className="grid grid-cols-2">
          <div>Chemistry (Grade A)</div> <div>Physics (Grade A)</div>
          <div>Mathematics (Grade A)</div>
          <div>Further Mathematics (Grade A)</div>
        </div>
        <br />
        <h3 className="font-medium">Summer 2021: A Levels:</h3>
        <div className="grid grid-cols-2">
          <div>Chemistry (Grade A*)</div> <div>Physics (Grade A*)</div>
          <div>Mathematics (Grade A*)</div>
          <div>Further Mathematics (Grade A*)</div>
        </div>
        <br />
        IOL Spanish Assessment: Distinction
      </MotionArticle>
    </TimelinePage>
  );
};

export default EducationPage;
